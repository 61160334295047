/* eslint-disable react/forbid-dom-props */
import React from 'react';
import { ensureChemUtils, peekChemUtils } from '@/shared/utils/chemUtils';
import { Alert, Checkbox, Fade, FormControlLabel, SxProps, Tab, Tabs, Theme, Tooltip, Typography } from '@mui/material';
import { TabContext, TabPanel } from '@mui/lab';
import { A } from '@/shared/components/sanitizedTags';
import { copyImage, copyText, downloadImage, downloadText, preparePNG, prepareSVGForExport, prepareSVGForURL } from './downloadUtils';
import { LIGHTBOX_SHOW_EVENT_CLICKED } from 'ASSETS/javascripts/light_box_handler';
import axios from 'axios';
import { MarvinCML } from '@cdd/ui-kit/lib/molRendering/v2/MarvinCML';
import { MolRenderOptions, RenderMoleculeSVG, NodeLocation, molfileIsValidAndHasAllZeroCoords } from '@cdd/ui-kit/lib/molRendering/v2/RenderMoleculeSVG';
import { ZoomingMonomers } from './ZoomingMonomers';
import { angleDiff, DEGRAD } from 'webmolkit/util/util';
import { V3000FullLayout } from '@cdd/ui-kit/lib/molRendering/v2/V3000FullLayout';
import { V3000Template } from '@cdd/ui-kit/lib/molRendering/v2/V3000Template';
import { MDLMOLReader } from 'webmolkit/io/MDLReader';
import { Molecule } from 'webmolkit/mol/Molecule';
import { MoleculeStream } from 'webmolkit/io/MoleculeStream';

type Props = {
  src: string; // molfile, CML or SMILES string
  moleculeName: string;
  dataURL: string;
  representationsCalculated: boolean;
  formatOriginal?: string;
  formatMolfile?: string;
  formatSMILES?: string;
  formatCXSMILES?: string;
  formatInChI?: string;
  formatInChIKey?: string;
  formatIUPAC?: string;
};

enum SelectedTab {
  SVG = 'tab_svg',
  PNG = 'tab_png',
  Molfile = 'tab_molfile',
  Original = 'tab_original',
  Identifiers = 'tab_identifiers',
}

type State = {
  beenOpened: boolean;
  chemUtils: ChemUtils;
  selectedTab: SelectedTab;
  inputWidth: string;
  inputHeight: string;
  copiedTypes: string[];
  formatOriginal?: string;
  formatMolfile?: string;
  formatSMILES?: string;
  formatCXSMILES?: string;
  formatInChI?: string;
  formatInChIKey?: string;
  formatIUPAC?: string;
  hasV3000Templates: boolean;
  showFullAtom: boolean;
  fullAtomMolfile?: string;
};

const PAYLOAD_W = 500, PAYLOAD_H = 500;
const SCALE_BASE = 30;
const MIN_SIZE = 50, MAX_SIZE = 2000;

export class DownloadMoleculeImage extends React.Component<Props, State> {
  private fileRoot: string;
  private displayMolfile: string = null;
  private displayMarvin: MarvinCML = null;
  private baseWidth = 0;
  private baseHeight = 0;
  private zoomingMonomers: ZoomingMonomers = null;
  private hasStartedFullLayout = false;
  private stopFullLayout = false;

  constructor(props: Props) {
    super(props);

    const chars: string[] = [];
    for (const ch of props.moleculeName) {
      const keep = /[A-Za-z0-9-]/.test(ch);
      chars.push(keep ? ch : '_');
    }
    this.fileRoot = chars.join('') || 'download';

    const chemUtils = peekChemUtils();
    this.defineBaseSize(chemUtils);

    this.state = {
      beenOpened: !props.dataURL,
      chemUtils,
      selectedTab: SelectedTab.SVG,
      inputWidth: Math.ceil(this.baseWidth).toString(),
      inputHeight: Math.ceil(this.baseHeight).toString(),
      copiedTypes: [],
      formatOriginal: this.props.formatOriginal,
      formatMolfile: this.props.formatMolfile,
      formatSMILES: this.props.formatSMILES,
      formatCXSMILES: this.props.formatCXSMILES,
      formatInChI: this.props.formatInChI,
      formatInChIKey: this.props.formatInChIKey,
      formatIUPAC: this.props.formatIUPAC,
      hasV3000Templates: false,
      showFullAtom: false,
    };
  }

  public render(): JSX.Element {
    if (!this.state.beenOpened) {
      return (
        <div
          className={LIGHTBOX_SHOW_EVENT_CLICKED}
          ref={(element) => this.observeActualElement(element)}>
          Loading...
        </div>
      );
    }

    if (!this.state.chemUtils) {
      setTimeout(() => {
        (async () => {
          const chemUtils = await ensureChemUtils();
          this.defineBaseSize(chemUtils);
          this.setState({
            chemUtils,
            inputWidth: Math.ceil(this.baseWidth).toString(),
            inputHeight: Math.ceil(this.baseHeight).toString(),
          });
        })();
      }, 1);
      return (<>Loading...</>);
    }

    const { selectedTab, inputWidth, inputHeight, copiedTypes } = this.state;
    const { hasV3000Templates, showFullAtom, fullAtomMolfile } = this.state;

    const hasSize = selectedTab == SelectedTab.SVG || selectedTab == SelectedTab.PNG;

    let width = parseInt(inputWidth), height = parseInt(inputHeight);
    if (!(width > MIN_SIZE)) width = MIN_SIZE;
    if (!(height > MIN_SIZE)) height = MIN_SIZE;
    width = Math.min(width, MAX_SIZE);
    height = Math.min(height, MAX_SIZE);

    const options: MolRenderOptions = { marvinCML: this.displayMarvin, isRegistration: true, angstromToPixels: 40 };
    let displayMolfile = this.displayMolfile;
    if (showFullAtom && fullAtomMolfile) {
      displayMolfile = fullAtomMolfile;
    }
    const isRendering = showFullAtom && !fullAtomMolfile;
    let render: RenderMoleculeSVG = null;
    if (!isRendering) {
      render = new RenderMoleculeSVG(displayMolfile, width, height, null, options, this.state.chemUtils);
      render.generate();
    }

    let copyAvailable = true;
    if (selectedTab == SelectedTab.PNG) {
      copyAvailable = navigator.clipboard && typeof ClipboardItem != 'undefined';
    }

    const panelW = `calc(${PAYLOAD_W}px + 4em)`;
    const panelH = `calc(${PAYLOAD_H}px + 4em)`;

    const showDownloadLine = selectedTab != SelectedTab.Identifiers;
    let elementCopy = (
      <A onClick={() => { if (copyAvailable) this.clickCopy(render); }}>
        <span className="fa fa-files-o icon-10" style={{ paddingRight: '0.5em' }} />
        Copy
      </A>
    );
    if (!copyAvailable) {
      const tooltipCopy = (
        <Typography>
          Your browser does not support this clipboard copy operation.
        </Typography>
      );
      elementCopy = (
        <Tooltip
          key="tip-copy"
          title={tooltipCopy}
          arrow
          placement="right"
        >
          <div className="DownloadMoleculeImage-unclickableLink">
            <span className="fa fa-files-o icon-10" style={{ paddingRight: '0.5em' }} />
            Copy
          </div>
        </Tooltip>
      );
    }

    const fullLayoutCheckbox = hasV3000Templates && (
      <FormControlLabel
        control={(
          <Checkbox
            checked={showFullAtom}
            onChange={(event) => this.handleChangeShowFullAtom(event.target.checked)}
            />
        )}
        label="Full atom layout"
        />
    );

    const elementWait = (<>Rendering...</>);

    return (
      <div
        tabIndex={0}
        onKeyUp={this.handleKeyPress}
        style={{ outline: 'none' }}
      >
        <TabContext value={this.state.selectedTab}>
          <div className="DownloadMoleculeImage-tabbar">
            <div>
              <Tabs value={selectedTab} onChange={this.handleChangeTab}>
                <Tab key={SelectedTab.SVG} label="SVG" value={SelectedTab.SVG} />
                <Tab key={SelectedTab.PNG} label="PNG" value={SelectedTab.PNG} />
                <Tab key={SelectedTab.Molfile} label="Molfile" value={SelectedTab.Molfile} />
                <Tab key={SelectedTab.Original} label="Original" value={SelectedTab.Original} disabled={!this.state.formatOriginal} />
                <Tab key={SelectedTab.Identifiers} label="Identifiers" value={SelectedTab.Identifiers} disabled={!this.state.formatSMILES} />
              </Tabs>
            </div>
          </div>
          <div className={`DownloadMoleculeImage-sizing ${hasSize ? '' : 'DownloadMoleculeImage-disabled'}`}>
            <div>Size</div>
            <input
              className="DownloadMoleculeImage-size-input"
              type="number"
              min={MIN_SIZE}
              max={MAX_SIZE}
              value={inputWidth}
              onChange={(event) => this.changeWidth(event.target.value)}
              disabled={!hasSize}
              autoFocus={true}
            />
            <div>{'\u{2715}'}</div>
            <input
              className="DownloadMoleculeImage-size-input"
              type="number"
              min={MIN_SIZE}
              max={MAX_SIZE}
              value={inputHeight}
              onChange={(event) => this.changeHeight(event.target.value)}
              disabled={!hasSize}
            />
            {fullLayoutCheckbox}
          </div>
          <div style={{ width: panelW, height: panelH }}>
            <TabPanel value={SelectedTab.SVG}>
              {isRendering ? elementWait : this.renderPreview(render, prepareSVGForURL(render.metaVector))}
            </TabPanel>
            <TabPanel value={SelectedTab.PNG}>
              {isRendering ? elementWait : this.renderPreview(render, preparePNG(render.metaVector))}
            </TabPanel>
            <TabPanel value={SelectedTab.Molfile}>
              {this.renderMolfile()}
            </TabPanel>
            <TabPanel value={SelectedTab.Original}>
              {this.renderOriginal()}
            </TabPanel>
            <TabPanel value={SelectedTab.Identifiers}>
              {this.renderIdentifiers()}
            </TabPanel>
          </div>
          <div
            className="DownloadMoleculeImage-downloadline"
            style={{ visibility: showDownloadLine ? 'inherit' : 'hidden' }}
          >
            <A onClick={() => this.clickDownload(render)}>
              <span className="fa fa-download icon-10" style={{ paddingRight: '0.5em' }} />
              Download
            </A>
            {elementCopy}
          </div>
        </TabContext>
        {copiedTypes.length > 0 && (
          <Fade in={true} timeout={1000}>
            <Alert
              className="DownloadMoleculeImage-alert"
              severity="success"
            >
              Copied {copiedTypes[0]} to clipboard.
            </Alert>
          </Fade>
        )}
      </div>
    );
  }

  public componentWillUnmount(): void {
    this.stopFullLayout = true;
  }

  private observeActualElement(element: HTMLElement): void {
    if (!element || this.state.beenOpened) return;

    const callbackBeenOpened = () => {
      if (this.state.beenOpened) return;

      this.setState({ beenOpened: true });

      if (!this.props.representationsCalculated) {
        axios.get(this.props.dataURL).then((response) => {
          this.setState({
            formatOriginal: response.data.structure_string,
            formatMolfile: response.data.molfile,
            formatSMILES: response.data.standard_smiles,
            formatCXSMILES: response.data.dearomatized_cxsmiles,
            formatInChI: response.data.standard_inchi,
            formatInChIKey: response.data.standard_inchi_key,
            formatIUPAC: response.data.iupac_name,
          });
        });
      }
    };

    element.addEventListener(LIGHTBOX_SHOW_EVENT_CLICKED, callbackBeenOpened);
  }

  private defineBaseSize(chemUtils: ChemUtils): void {
    if (this.displayMolfile) return;
    const { src } = this.props;
    if (!src || !chemUtils) return;

    const isMolfile = (structure: string) => {
      return !!/^M\s+END\s*/m.exec(structure);
    };

    const isMarvinCML = (structure: string) => {
      return !!/<cml/m.exec(structure);
    };

    if (isMarvinCML(src)) {
      this.displayMarvin = new MarvinCML(src);
      try {
        this.displayMarvin.parse();
      } catch (ex) {
        this.displayMarvin = null;
      }
      this.displayMolfile = this.displayMarvin.getMolfile();
    } else if (isMolfile(src)) {
      this.displayMolfile = src;
      if (molfileIsValidAndHasAllZeroCoords(src)) {
        const smiles = chemUtils.molToSmiles(src);
        this.displayMolfile = chemUtils.smilesToMol(smiles);
      }
    }
    if (!this.renderMolfile) {
      this.displayMolfile = this.state.formatMolfile;
    }

    const options: MolRenderOptions = {
      marvinCML: this.displayMarvin,
      angstromToPixels: SCALE_BASE,
    };
    const render = new RenderMoleculeSVG(this.displayMolfile, null, null, null, options, chemUtils);
    render.generate();
    this.baseWidth = render.metaVector.width;
    this.baseHeight = render.metaVector.height;

    if (render.v3000template.anyTemplates) {
      setTimeout(() => {
        this.setState({ hasV3000Templates: true });
      }, 0);
    }
  }

  private renderPreview(render: RenderMoleculeSVG, src: string): JSX.Element {
    const gfx = render.metaVector;

    if (!this.zoomingMonomers) {
      this.zoomingMonomers = new ZoomingMonomers(render);
      this.zoomingMonomers.build();
    }

    const scale = Math.min(1, Math.min(PAYLOAD_W / gfx.width, PAYLOAD_H / gfx.height));
    const width = Math.ceil(gfx.width * scale), height = Math.ceil(gfx.height * scale);

    const percentScale = Math.floor(100 * Math.min(PAYLOAD_W / gfx.width, PAYLOAD_H / gfx.height));
    const scalenote = percentScale < 100 && (
      <div key="note" className="DownloadMoleculeImage-scalenote">
        scale {percentScale}%
      </div>
    );

    const renderNodePreview = (nodeLoc: NodeLocation): JSX.Element => {
      if (!this.zoomingMonomers.needsPreview(nodeLoc.atomIndex)) return null;

      const x = (nodeLoc.cx - nodeLoc.rad) * scale, y = (nodeLoc.cy - nodeLoc.rad) * scale, diam = 2 * nodeLoc.rad * scale;

      if (!this.zoomingMonomers.hasPreview(nodeLoc.atomIndex)) {
        const callback = () => {
          this.zoomingMonomers.getPreview(nodeLoc.atomIndex);
          this.forceUpdate();
        };

        return (
          <div
            key={`mouse${nodeLoc.atomIndex}`}
            style={{ left: `${x}px`, top: `${y}px`, width: `${diam}px`, height: `${diam}px` }}
            className="DownloadMoleculeImage-outline-mouseover"
            onMouseEnter={callback}
          />
        );
      }

      const preview = this.zoomingMonomers.getPreview(nodeLoc.atomIndex);

      const tooltip = (
        <Typography>
          <span style={{ display: 'block' }} dangerouslySetInnerHTML={{ __html: preview.svg }} />
        </Typography>
      );

      const sx: SxProps<Theme> = {
        maxWidth: `${preview.width + 5}px`,
        maxHeight: `${preview.height + 5}px`,
        backgroundColor: 'rgb(224 224 224 / 50%)',
      };

      // const theta = (preview.theta * 180 / Math.PI + 360) % 360;
      let placement: string = null;
      const COMPASS_POINTS = {
        'right': 0 * DEGRAD,
        'top': 90 * DEGRAD,
        'left': 180 * DEGRAD,
        'bottom': 270 * DEGRAD,
        'top-start': 45 * DEGRAD,
        'top-end': 135 * DEGRAD,
        'bottom-end': 225 * DEGRAD,
        'bottom-start': 315 * DEGRAD,
      };
      let closest = Number.POSITIVE_INFINITY;
      for (const [code, theta] of Object.entries(COMPASS_POINTS)) {
        const dist = Math.abs(angleDiff(preview.theta, theta));
        if (dist < closest) {
          placement = code;
          closest = dist;
        }
      }

      /* eslint-disable @typescript-eslint/no-explicit-any */
      return (
        <Tooltip
          key={`mouse${nodeLoc.atomIndex}`}
          title={tooltip}
          arrow
          componentsProps={{ tooltip: { sx } }}
          placement={placement as any}
        >
          <div
            style={{ left: `${x}px`, top: `${y}px`, width: `${diam}px`, height: `${diam}px` }}
            className="DownloadMoleculeImage-outline-mouseover">
          </div>
        </Tooltip>
      );
      /* eslint-enable @typescript-eslint/no-explicit-any */
    };

    return (
      <div
        className="DownloadMoleculeImage-center"
        style={{ width: `${PAYLOAD_W}px`, height: `${PAYLOAD_H}px` }}
      >
        <div key="preview" className="DownloadMoleculeImage-centrepoint">
          <div className="DownloadMoleculeImage-outline">
            <img
              src={src}
              style={{ display: 'block', width: `${width}px`, height: `${height}px` }}
            />
            {render.nodeLocations.map((nodeLoc) => renderNodePreview(nodeLoc))}
          </div>
          {scalenote}
        </div>
      </div>
    );
  }

  private renderMolfile(): JSX.Element {
    const { formatMolfile, showFullAtom, fullAtomMolfile } = this.state;
    const molfile = showFullAtom && fullAtomMolfile ? fullAtomMolfile : formatMolfile;

    return (
      <textarea
        className="DownloadMoleculeImage-textraw"
        style={{ width: `${PAYLOAD_W}px`, height: `${PAYLOAD_H}px` }}
        value={molfile}
        readOnly
      >
      </textarea>
    );
  }

  private renderOriginal(): JSX.Element {
    return (
      <textarea
        className="DownloadMoleculeImage-textraw"
        style={{ width: `${PAYLOAD_W}px`, height: `${PAYLOAD_H}px` }}
        value={this.state.formatOriginal}
        readOnly
      >
      </textarea>
    );
  }

  private renderIdentifiers(): JSX.Element {
    const { formatSMILES, formatCXSMILES, formatInChI, formatInChIKey, formatIUPAC } = this.state;

    const tooltipSMILES = (
      <Typography>
        Line notation that does not preserve coordinates, resonance localization or advanced stereochemistry
        (<b>s</b> to copy to clipboard)
      </Typography>
    );

    const tooltipCXSMILES = (
      <Typography>
        Line notation based on SMILES that supports more features. However, it does not retain all the details supported by V3000 Molfiles
        (<b>x</b> to copy to clipboard)
      </Typography>
    );

    const tooltipInChI = (
      <Typography>
        International Chemical Identifier which provides a canonically unique nomenclature for the structure
        (<b>i</b> to copy to clipboard)
      </Typography>
    );

    const tooltipInChIKey = (
      <Typography>
        Derivative of the InChI which can be used as a fixed-length hash key
        (<b>k</b> to copy to clipboard)
      </Typography>
    );

    const tooltipIUPAC = (
      <Typography>
        Chemical name derived by an algorithm which follows the IUPAC naming rules
        (<b>n</b> to copy to clipboard)
      </Typography>
    );

    return (
      <div className="DownloadMoleculeImage-notation-grid">
        <div style={{ gridArea: '1 / label' }} className="DownloadMoleculeImage-tipfill">
          <Tooltip
            title={tooltipSMILES}
            arrow
            placement="left"
          >
            <div className="DownloadMoleculeImage-tipfill">SMILES</div>
          </Tooltip>
        </div>
        <input style={{ gridArea: '1 / value' }} className="DownloadMoleculeImage-readonly-input" value={formatSMILES} readOnly />
        <A style={{ gridArea: '1 / download' }} onClick={() => downloadText(formatSMILES, `${this.fileRoot}.smiles`, 'text/plain')}>
          <span className="fa fa-download icon-10" />
        </A>
        <A style={{ gridArea: '1 / copy' }} onClick={() => this.copyTextNotify(formatSMILES, 'SMILES')}>
          <span className="fa fa-files-o icon-10" />
        </A>

        <div style={{ gridArea: '2 / label' }} className="DownloadMoleculeImage-tipfill">
          <Tooltip
            title={tooltipCXSMILES}
            arrow
            placement="left"
          >
            <div className="DownloadMoleculeImage-tipfill">CXSMILES</div>
          </Tooltip>
        </div>
        <input style={{ gridArea: '2 / value' }} className="DownloadMoleculeImage-readonly-input" value={formatCXSMILES} readOnly />
        <A style={{ gridArea: '2 / download' }} onClick={() => downloadText(formatCXSMILES, `${this.fileRoot}.cxsmiles`, 'text/plain')}>
          <span className="fa fa-download icon-10" />
        </A>
        <A style={{ gridArea: '2 / copy' }} onClick={() => this.copyTextNotify(formatCXSMILES, 'CXSMILES')}>
          <span className="fa fa-files-o icon-10" />
        </A>

        <div style={{ gridArea: '3 / label' }} className="DownloadMoleculeImage-tipfill">
          <Tooltip
            title={tooltipInChI}
            arrow
            placement="left"
          >
            <div className="DownloadMoleculeImage-tipfill">InChI</div>
          </Tooltip>
        </div>
        <input style={{ gridArea: '3 / value' }} className="DownloadMoleculeImage-readonly-input" value={formatInChI} readOnly />
        <A style={{ gridArea: '3 / download' }} onClick={() => downloadText(formatInChI, `${this.fileRoot}.inchi`, 'text/plain')}>
          <span className="fa fa-download icon-10" />
        </A>
        <A style={{ gridArea: '3 / copy' }} onClick={() => this.copyTextNotify(formatInChI, 'InChI')}>
          <span className="fa fa-files-o icon-10" />
        </A>

        <div style={{ gridArea: '4 / label' }} className="DownloadMoleculeImage-tipfill">
          <Tooltip
            title={tooltipInChIKey}
            arrow
            placement="left"
          >
            <div className="DownloadMoleculeImage-tipfill">InChIKey</div>
          </Tooltip>
        </div>
        <input style={{ gridArea: '4 / value' }} className="DownloadMoleculeImage-readonly-input" value={formatInChIKey} readOnly />
        <A style={{ gridArea: '4 / download' }} onClick={() => downloadText(formatInChIKey, `${this.fileRoot}.inchikey`, 'text/plain')}>
          <span className="fa fa-download icon-10" />
        </A>
        <A style={{ gridArea: '4 / copy' }} onClick={() => this.copyTextNotify(formatInChIKey, 'InChIKey')}>
          <span className="fa fa-files-o icon-10" />
        </A>

        <div style={{ gridArea: '5 / label' }} className="DownloadMoleculeImage-tipfill">
          <Tooltip
            title={tooltipIUPAC}
            arrow
            placement="left"
          >
            <div className="DownloadMoleculeImage-tipfill">IUPAC</div>
          </Tooltip>
        </div>
        <input style={{ gridArea: '5 / value' }} className="DownloadMoleculeImage-readonly-input" value={formatIUPAC} readOnly />
        <A style={{ gridArea: '5 / download' }} onClick={() => downloadText(formatIUPAC, `${this.fileRoot}.iupac`, 'text/plain')}>
          <span className="fa fa-download icon-10" />
        </A>
        <A style={{ gridArea: '5 / copy' }} onClick={() => this.copyTextNotify(formatIUPAC, 'IUPAC')}>
          <span className="fa fa-files-o icon-10" />
        </A>
      </div>
    );
  }

  private changeWidth(strW: string) {
    const width = Math.min(parseInt(strW) >= MIN_SIZE ? parseInt(strW) : MIN_SIZE, MAX_SIZE);
    const height = this.baseHeight * (width / this.baseWidth);
    const strH = Math.ceil(height).toString();
    this.setState({ inputWidth: strW, inputHeight: strH });
  }

  private changeHeight(strH: string) {
    const height = Math.min(parseInt(strH) >= MIN_SIZE ? parseInt(strH) : MIN_SIZE, MAX_SIZE);
    const width = this.baseWidth * (height / this.baseHeight);
    const strW = Math.ceil(width).toString();
    this.setState({ inputWidth: strW, inputHeight: strH });
  }

  private handleChangeTab = (_event: React.ChangeEvent, val: string) => {
    this.setState({ selectedTab: val as SelectedTab });
  };

  private clickDownload(render: RenderMoleculeSVG): void {
    if (!render) return;
    const gfx = render.metaVector;

    const { selectedTab } = this.state;
    if (selectedTab == SelectedTab.SVG) {
      const svg = prepareSVGForExport(gfx);
      downloadText(svg, `${this.fileRoot}.svg`, 'image/svg+xml');
    } else if (selectedTab == SelectedTab.PNG) {
      downloadImage(gfx, `${this.fileRoot}.png`);
    } else if (selectedTab == SelectedTab.Molfile) {
      const { formatMolfile, showFullAtom, fullAtomMolfile } = this.state;
      const molfile = showFullAtom && fullAtomMolfile ? fullAtomMolfile : formatMolfile;
      downloadText(molfile, `${this.fileRoot}.mol`, 'text/plain');
    } else if (selectedTab == SelectedTab.Original) {
      downloadText(this.state.formatOriginal, `${this.fileRoot}.txt`, 'text/plain');
    }
  }

  private clickCopy(render: RenderMoleculeSVG): void {
    if (!render) return;
    const gfx = render.metaVector;

    const { selectedTab } = this.state;
    if (selectedTab == SelectedTab.SVG) {
      const svg = prepareSVGForExport(gfx);
      copyText(svg);
      this.copyTextNotify(svg, 'SVG');
    } else if (selectedTab == SelectedTab.PNG) {
      copyImage(gfx);
      this.notifyClipboard('PNG');
    } else if (selectedTab == SelectedTab.Molfile) {
      const { formatMolfile, showFullAtom, fullAtomMolfile } = this.state;
      const molfile = showFullAtom && fullAtomMolfile ? fullAtomMolfile : formatMolfile;
      this.copyTextNotify(molfile, 'Molfile');
    } else if (selectedTab == SelectedTab.Original) {
      this.copyTextNotify(this.state.formatOriginal, 'Original');
    }
  }

  private copyTextNotify(txt: string, typeName: string): void {
    if (!txt) return;
    copyText(txt);
    this.notifyClipboard(typeName);
  }

  private notifyClipboard(copiedType: string): void {
    this.setState({ copiedTypes: [copiedType, ...this.state.copiedTypes] });
    setTimeout(() => {
      const { copiedTypes } = this.state;
      this.setState({ copiedTypes: copiedTypes.slice(0, copiedTypes.length - 1) });
    }, 10000);
  }

  private handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key == 'm') {
      this.copyTextNotify(this.state.formatMolfile, 'Molfile');
    } else if (event.key == 's') {
      this.copyTextNotify(this.state.formatSMILES, 'SMILES');
    } else if (event.key == 'x') {
      this.copyTextNotify(this.state.formatCXSMILES, 'CXSMILES');
    } else if (event.key == 'i') {
      this.copyTextNotify(this.state.formatInChI, 'InChI');
    } else if (event.key == 'k') {
      this.copyTextNotify(this.state.formatInChIKey, 'InChIKey');
    } else if (event.key == 'n') {
      this.copyTextNotify(this.state.formatIUPAC, 'IUPAC');
    }
  };

  private handleChangeShowFullAtom(checked: boolean): void {
    this.setState({ showFullAtom: checked });

    if (checked && !this.hasStartedFullLayout) {
      this.startFullLayout();
    } else {
      const molfile = checked ? this.state.fullAtomMolfile : this.displayMolfile;
      this.recalibrateSize(molfile);
      this.setState({
        inputWidth: Math.ceil(this.baseWidth).toString(),
        inputHeight: Math.ceil(this.baseHeight).toString(),
      });
    }
  }

  private startFullLayout(): void {
    let reader: MDLMOLReader = null, mol: Molecule = null;
    try {
      reader = new MDLMOLReader(this.displayMolfile);
      reader.parse();
      mol = reader.mol;
      mol.keepTransient = true;
    } catch (ex) {
      return; // silent failure
    }

    this.hasStartedFullLayout = true;

    const v3000template = new V3000Template(mol, reader.scsrTemplates);
    v3000template.process();
    const fullMol = v3000template.buildFullMolecule();

    const v3000full = new V3000FullLayout(v3000template, fullMol);
    v3000full.prepare();

    const iterateFullLayout = (): void => {
      if (this.stopFullLayout) return;

      const timeStart = new Date().getTime();
      const INCR = 20; // # milliseconds of work to do, ideally

      while (true) {
        if (v3000full.iterate()) {
          const fullAtomMolfile = MoleculeStream.writeMDLMOL(fullMol);
          this.recalibrateSize(fullAtomMolfile);

          this.setState({
            fullAtomMolfile,
            inputWidth: Math.ceil(this.baseWidth).toString(),
            inputHeight: Math.ceil(this.baseHeight).toString(),
          });
          return;
        }
        if (new Date().getTime() - timeStart > INCR) break;
      }

      setTimeout(iterateFullLayout, 0);
    };

    setTimeout(iterateFullLayout, 0);
  }

  private recalibrateSize(molfile: string): void {
    const options: MolRenderOptions = { marvinCML: this.displayMarvin, isRegistration: true, angstromToPixels: SCALE_BASE };
    const render = new RenderMoleculeSVG(molfile, null, null, null, options, this.state.chemUtils);
    render.generate();
    this.baseWidth = render.metaVector.width;
    this.baseHeight = render.metaVector.height;
  }
}
